<template>
	<div class="error">
		<div class="errorBox">
			<svg-icon class="errorIcon" iconClass="error"></svg-icon>
			该页面还未开放
			<div class="backBtn" @click="goBack">返回上一页</div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src

	export default {
		name: 'CustomizedService',
		components: {},
		methods: {
			goBack() {
				this.$router.go(-1);
			}
		}
	}
</script>
<style scoped lang="scss">
	.error {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		font-size: 40px;

		.errorIcon {
			height: 60px;
			width: 60px;
			margin: 0 auto 20px;
			display: block;
		}

		.errorBox {
			text-align: center;
		}

		.backBtn {
			width: 150px;
			height: 40px;
			border-radius: 8px;
			background: #1e2e54;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 16px;
			margin: 10px auto;
			color: #fff;
			cursor: pointer;
		}
	}
</style>
